import React from 'react'
import GeoChat from './geochat/geochat'

class SplashScreen extends React.Component {
  
    componentDidMount () {
    }
  
    componentWillUnmount () {

    }
  
 
    render () {
      return (
          <div className="splashScreen">
			  <div className="geochat">
			  	<GeoChat/>
			  </div>
			<div className="splashScreen-loading-container"> 
			  <div className="splashScreen-loading-pos">
								<svg className="splashScreen-loading" version="1.1" id="Layer_1" x="0px" y="0px"
									viewBox="0 0 57.691 58.2744" >
				<path d="M28.8457,2.4057c-14.7402,0-26.7319,11.9917-26.7319,26.7314s11.9917,26.7314,26.7319,26.7314
					c14.7397,0,26.7314-11.9917,26.7314-26.7314S43.5855,2.4057,28.8457,2.4057z M28.8457,52.8686
					c-13.0859,0-23.7319-10.646-23.7319-23.7314c0-12.5812,9.8428-22.9019,22.2319-23.6791v23.6791h3V5.4581
					C42.7344,6.2353,52.5772,16.556,52.5772,29.1372C52.5772,42.2226,41.9312,52.8686,28.8457,52.8686z"/>
								</svg>
				</div>
				<img className="splashScreen-svg" src="../fonts/loading.svg" />
				

              
		</div>
		<div className="splashScreen-contributors">
			  	András Cséfalvay, Alice dos Reis, Halima Haruna, Holly Childs<br/> Danae Io, Adéla Součková
			</div>
          </div>
      )
    }
  }
  
  
  export default SplashScreen