import React from 'react'
import Vimeo from '@u-wave/react-vimeo';

class Menu extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {               
        return (
            <div className="menu-container">
                <div className="mt-1 menu-text">
                The task of the research exhibition 'Technologies of the Sacred' is to rethink the boundaries between technology and religion or mysticism. The disenchanted world of the modern era is but a continuation of sacral tendencies by other means—magical thinking is camouflaged as technical rationality, which needs the sphere of the sacred as its hidden source of secular majesty. This approach allows us to both reformulate our relationship to what technology essentially is, and to craft new cultural practices for an age when the world as we know it is irretrievably falling apart. What is it that we should be mourning, and what songs of praise should we be singing to the world to come?
                </div>
                <div className="mt-1 menu-text-cz">
                Úkolem výzkumné výstavy ‚Technologie posvátna‘ je přehodnotit hranice mezi technologií a náboženstvím či mysticismem. Odkouzlený svět moderní doby je v skutečnosti jenom pokračováním sakrálních tendencí jiným způsobem – magické myšlení se kamufluje do podoby technické racionality, která potřebuje sféru posvátna jako skrytý zdroj své sekulární vznešenosti. Tento přístup nám umožňuje nově promyslet nejen náš vztah k tomu, co technologie ve své podstatě je, ale také se zamyslet nad tím, jak jejím prostřednictvím utvářet kulturní praktiky v době, kdy se svět tak, jak ho známe, začíná nenávratně rozpadat. Co z tohoto světa máme vlastně oplakávat, a jaké chvalozpěvy zpívat světu, jež přijde?
                </div>
                <div className="menu-credits">
                    <div>Artists / Vystavující: András Cséfalvay, Alice dos Reis, Halima Haruna, Holly Childs, Danae Io, Adéla Součková</div>
                    <div>Interface Design, 3D, Code / Design rozhraní: The Rodina</div>
                    <div>Graphic Design / Grafický design: Jan Novák</div>
                    <div>IT Support / IT podpora: Jiří Maha</div>
                    <div>Curators / Kurátoři/ky: Lukáš Likavčan and Display – Association for Research and Collective Practice</div>
                    <div>Conceptual Collaboration / Konceptuální spolupráce: Hana Janečková and Display – Association for Research and Collective Practice</div>
                    <div>Project Partners / Partneři projektu: Ministry of Culture Czech Republic, City of Prague, Embassy of Greece in Prague, MeetFactory / Ministerstvo kultury České republiky, Magistrát města Praha, Velvyslanectví Řecké republiky v ČR, MeetFactory</div>
                </div>
                <div className="menu-credits-adela">
                    <div>Adéla Součková would like to thank the following collaborators on her artwork 'Ways to Connect: Lullaby to the Landscape'. Voices: Tinka Avramova, Nino Naneishvili and Iaoni. Sound: Petr Váně. With support of Silk Museum (Georgia) and Awagami Factory (Japan). / Adéla Součková by ráda poděkovala spolupracovníkům a spolupracovnicím na jejím díle ‚Ways to Connect: Lullaby to the Landscape‘. Hlas: Tinka Avramova, Nino Naneishvili a Iaoni. Zvuk: Petr Váně. Vzniklo díky podpoře Silk Museum (Gruzie) a Awagami Factory (Japonsko).</div>
                </div>
                <div className="mt-1">
                Copyright Display - Association for Research and Collective Practice © 2020
                </div>
            </div>
        )
    }
}


export default Menu 