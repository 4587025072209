import React from 'react'
import Vimeo from '@u-wave/react-vimeo';

class Andras extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {               
        return (
            <div className="artwork-container">
                <div className="artwork-container-close" onClick={this.props.close}>Close</div>
                <div className="video-container">
                    <div className="video-container-after" style={{ paddingBottom: '56.25%'}}>                               
                    </div>
                    <div className="video-container-content">
                        <Vimeo
                            video="413177254"
                            autoplay
                            className="artwork-videoplayer"
                            // controls={false}
                        />
                    </div>
                </div>
                <div className="artwork-caption">András Cséfalvay: Gates of Sanity (2019), video, duration 5:39</div>
            </div>
        )
    }
}


export default Andras