import React from 'react'
import Vimeo from '@u-wave/react-vimeo';

class Io extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {               
        return (
            <div className="artwork-container">
                <div className="artwork-container-close" onClick={this.props.close}>Close</div>
                <div className="video-container">
                    <div className="video-container-after" style={{ paddingBottom: '56.25%'}}>                               
                    </div>
                    <div className="video-container-content">
                        <Vimeo
                            video="408857312"
                            autoplay
                            className="artwork-videoplayer"
                            // controls={false}
                        />
                    </div>
                </div>
                <div className="artwork-caption">Danae Io: The lips, the lisp, the slip of the tongue (2018), video, duration 15:22</div>
            </div>
        )
    }
}


export default Io 