import React from 'react'
import { Link } from "react-router-dom"

class Andras extends React.Component {

    constructor(props) {
        super(props)
        var userLang = navigator.language || navigator.userLanguage; 
        this.state = {
            locale: userLang
        }
    }

    componentDidMount() {
        // if (this.state.userLang === 'cs-CZ') {
        //     this.history.push('/cs')
        // }
    }

    

    render() {               
        return (
            <div className="fp-container">
                <div className="language">
                    <Link to='/cs'>en</Link>/<Link to='/'>cs</Link>
                </div>
                {/* <div className="fp-introduction">
                Display is presenting to you two translation of essays related to our online exhibition Technology of The Sacred. 
                </div> */}
                <div className="fp-col-container">
                    <Link to="/introduction" className="fp-col-width">
                        <h2 className="fp-title">Modernity is a continuation of sacral tendencies by other means</h2>
                        <div className="fp-subtitle">Introduction to Technologies of the Sacred<br></br>Lukáš Likavčan &amp; Hana Janečková</div>
                        <div className="fp-main">
                        Religious practices have always been tightly connected to technological
mediation. Just recall the stories of Old Testament’s Tabernacle, complex
mathematics of Yoruba divination practices, Buddhist spiritual automation by
prayer wheels, Japanese history of robotics...

                        </div>
                        <div to="/introduction" className="fp-read">
                            <div className="fp-read-bck">
                            <svg height="100" width="100" viewBox="0 0 100 100" preserveAspectRatio="none">
                                <circle cx="50" cy="50" r="40" stroke="white" strokeWidth="3" fill="white" />
                            </svg>

                            </div>
                            <div className="fp-read-text">read 1,036 words</div>
                        </div>
                    </Link>
                    <Link to="/space" className="fp-col-width">
                        <h2 className="fp-title">Tech of the Sacred</h2>
                        <div className="fp-subtitle">Online Exhibition<br /><br />András Cséfalvay, Alice dos Reis, Halima Haruna, Holly Childs<br/> Danae Io, Adéla Součková</div>
                        <div className="fp-main">
                            The task of the research exhibition 'Technologies of the Sacred' is to rethink the boundaries between technology and religion or mysticism. The disenchanted world of the modern era is but a continuation of sacral tendencies by other means—magical thinking...
                        </div>
                        <div className="fp-read">
                            <div className="fp-read-bck">
                            <svg height="100" width="100" viewBox="0 0 100 100" preserveAspectRatio="none">
                                <circle cx="50" cy="50" r="40" stroke="white" strokeWidth="3" fill="white" />
                            </svg>

                            </div>
                            <div className="fp-read-text">visit exhibition</div>
                        </div>
                    </Link>
                    <Link to="/prayer" className="fp-col-width">
                        <h2 className="fp-title">WHAT IF TECHNOLOGY WERE A PRAYER?</h2>
                        <div className="fp-subtitle">Interview with K Allado-McDowell <br/>Danae Io</div>
                        <div className="fp-main">
                        K Allado-McDowell co-leads the Artist + Machine Intelligence program at Google Research, facilitating collaboration between Google AI researchers, artists, and cultural institutions. Alongside twenty years of experience in software design and engineering, K also has an MFA in photography and regularly performs acoustic...

                        </div>
                        <div to="/technicandmagic" className="fp-read">
                            <div className="fp-read-bck">
                            <svg height="100" width="100" viewBox="0 0 100 100" preserveAspectRatio="none">
                                <circle cx="50" cy="50" r="40" stroke="white" strokeWidth="3" fill="white" />
                            </svg>

                            </div>
                            <div className="fp-read-text">read 2,490 words</div>
                        </div>
                    </Link>
                    <Link to="/technicandmagic" className="fp-col-width">
                        <h2 className="fp-title">TECHNIC <br/>AND<br/> MAGIC: INTRODUCTION</h2>
                        <div className="fp-subtitle">Federico Campagna</div>
                        <div className="fp-main">
                            This is a book for those who lie defeated by history and by the present. It isn’t a manual to turn the current defeat into a future triumph, but a rumour about a passage hidden within the battlefield leading to a forest beyond it. I began to write this book in the late autumn of 2016, at the time when resurging fascism had joined environmental devastation and capitalist biopolitics, in the bleak Valhalla of worldmaking forces. In earlier...
                        </div>
                        <div to="/technicandmagic" className="fp-read">
                            <div className="fp-read-bck">
                            <svg height="100" width="100" viewBox="0 0 100 100" preserveAspectRatio="none">
                                <circle cx="50" cy="50" r="40" stroke="white" strokeWidth="3" fill="white" />
                            </svg>

                            </div>
                            <div className="fp-read-text">read 3,254 words</div>
                        </div>
                    </Link>
                </div>

            </div>
        )
    }
}


export default Andras