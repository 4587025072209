const messagesStore = [
    {type: 'text', content: ' 🙂 Be patient'},
    // {type: 'image', content: '/images/01.png'},
    {type: 'text', content: 'your device'},
    {type: 'text', content: 'is in the process of migration'},
    {type: 'text', content: 'intimacy of the internet connection'},
    {type: 'text', content: 'Techno Occult'},
    {type: 'text', content: ',it may take a while to load'},
    {type: 'text', content: 'All Dear Technofossils'},
    {type: 'text', content: 'All Dear data'},
    {type: 'text', content: 'Autonomous Sea WIFI Waves'},
    {type: 'text', content: 'Surveillance, Productivity, Reputation …'},
    {type: 'text', content: 'I am the Voice'},
    {type: 'text', content: 'of'},
    {type: 'text', content: 'loading'},
    {type: 'text', content: 'Cloud Could or Couldn’t'},
    {type: 'text', content: 'be faster'},
    {type: 'text', content: 'be somewhere else'},
    {type: 'text', content: 'Surface Stories Go Viral'},
    {type: 'text', content: 'Desert of Backups'},
    {type: 'text', content: 'Avatar Seeking a Refuge'},
    {type: 'text', content: 'No Gravity – No Teardrop'},
    {type: 'text', content: 'Hi!  ¯\_(ツ)_/¯'},
    {type: 'text', content: 'still watching me?'},
    {type: 'text', content: 'Loner'},
    {type: 'text', content: 'Forever Old'},
    {type: 'text', content: 'I am here'},
    {type: 'text', content: 'Evolution and Enlightment'},
    {type: 'text', content: 'Space Jam on the Orbit'},
    {type: 'text', content: 'Artificial Stellite Trash'},
    {type: 'text', content: 'infinite exhibition loader'},
]

export default messagesStore

// Be patient, depending on your device and internet connection, it may take a while to load the full environment...