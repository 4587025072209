import React, { Suspense } from 'react'
import {
    Router as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom"
import { CSSTransition } from 'react-transition-group'

import { createBrowserHistory } from "history";

// import BabylonScene from './BabylonScene'
const BabylonScene = React.lazy(() => import('./BabylonScene'));
import SplashScreen from './SplashScreen'
import Header from './Header'
import Menu from './Menu'
import Instructions from './Instructions'

import Io from './pages/Io'
import Reis from './pages/Reis'
import Haruna from './pages/Haruna'
import Andras from './pages/Andras'

import Frontpage from './textpages/Frontpage'
import FrontpageCz from './textpages/FrontpageCs'
import TechnicAndMagic from './textpages/TechnicAndMagic'
import TechnicAndMagicCs from './textpages/TechnicAndMagicCs'
import Prayer from './textpages/Prayer'
import PrayerCs from './textpages/PrayerCs'
import Introduction from './textpages/Introduction'
import IntroductionCs from './textpages/IntroductionCs'

const history = createBrowserHistory();

class PageWithScene extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isMenuOpen: false,
            isSceneRendering: true,
            interaction: 0,
            isSceneLoaded: false,
            volume: 1,
            showInstructions: true,
            isActive: false,
            total: null,
            clients: null,
       }
    }

    handleCanvasInteraction = (value)=>{
        this.setState((state) => {
            return {interaction: state.interaction + 1, isSceneRendering: !state.isSceneRendering }
          }); 
        switch (value) {  
            case 'io':
                history.push('/space/io')
                return
            case 'reis':
                history.push('/space/reis')
                return
            case 'haruna':
                history.push('/space/haruna')
                return
            case 'andras':
                history.push('/space/andras')
                return
            default:
                console.log('Unknown action: ' + value)
        }
    }

    handleOnLoad = () => {
        this.setState({
            isSceneLoaded: true
        })
    }

    handleOnMute = () => {
        this.setState((state) => {
            if (state.volume === 0)
                return { volume: 1 }
            if (state.volume === 1)
                return { volume: 0 }    
        })
    }

    handleBack= () => {
        this.setState({isSceneRendering: true})
        history.push('/space')
    }

    returnState = ()=>{
        return this.state
    }

    menuToggle = ()=> {
        this.setState((state) => {
            return { isMenuOpen: !state.isMenuOpen }
          })
    }

    hideInstructions = ()=> {
        this.setState({showInstructions: false})
    }

    updateStatus = (payload)=> {
        this.setState({...payload})
    }

    render() {               
        return (
            <div className="main-container">
                <Header handleOnClick={this.menuToggle} handleMute={this.handleOnMute} volume={this.state.volume} updateStatus={this.updateStatus} location={location}/>
                <CSSTransition in={this.state.isMenuOpen} timeout={200} classNames="menu-animation" unmountOnExit>
                        <Menu clients={this.state.clients} total={this.state.total} isActive={this.state.isActive} />
                </CSSTransition>
                <Router history={history}>
                    <div className="main">
                        <Switch location={location}>
                            <Route path="/about">
                                    <div>About</div>
                            </Route>
                            <Route path="/space/io">
                                    <Io close={this.handleBack}/>
                            </Route>
                            <Route path="/space/reis">
                                <Reis close={this.handleBack}/>
                            </Route>
                            <Route path="/space/haruna">
                                <Haruna close={this.handleBack}/>
                            </Route>
                            <Route path="/space/andras">
                                <Andras close={this.handleBack}/>
                            </Route>

                            <Route exact path="/">
                                <FrontpageCz history={{ history }}/>
                            </Route>
                            <Route exact path="/en">
                                <Frontpage history={{ history }}/>
                            </Route>

                            <Route exact path="/technicandmagic">
                                <TechnicAndMagic history={{ history }}/>
                            </Route>
                            <Route exact path="/cs/technicandmagic">
                                <TechnicAndMagicCs history={{ history }}/>
                            </Route>

                            <Route exact path="/prayer">
                                <Prayer history={{ history }}/>
                            </Route>
                            <Route exact path="/cs/prayer">
                                <PrayerCs history={{ history }}/>
                            </Route>

                            <Route exact path="/introduction">
                                <Introduction history={{ history }}/>
                            </Route>
                            <Route exact path="/cs/introduction">
                                <IntroductionCs history={{ history }}/>
                            </Route>
                        </Switch>
                        <Switch location={location}>
                            <Route path="/space">
                                <CSSTransition in={!this.state.isSceneLoaded} timeout={1000} classNames="splashScreen-animation" unmountOnExit>
                                    <div>
                                        <SplashScreen />
                                    </div>
                                </CSSTransition>
                                <CSSTransition in={this.state.showInstructions} timeout={1000} classNames="splashScreen-animation" unmountOnExit>
                                    <div>
                                    <Instructions/>
                                    </div>
                                </CSSTransition>
                                <Suspense fallback={<div>Loading...</div>}>              
                                    <BabylonScene 
                                        handleCanvasInteraction={this.handleCanvasInteraction} 
                                        getState={this.returnState} 
                                        handleOnLoad={this.handleOnLoad}
                                        handleOnMute={this.handleOnMute}
                                        hideInstructions={this.hideInstructions}
                                        // width={100}
                                        // height={100}
                                        />
                                </Suspense>  
                            </Route>
                        </Switch>
                    </div>
                </Router>   
            </div>
        )
    }
}


export default PageWithScene 