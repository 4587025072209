import React from 'react'
import { Link } from 'react-router-dom'
import HeaderImage from './HeaderImage'
import HeaderImageSmall from './HeaderImageSmall'
// import Text from './TextTechnicAndMagic.jsx'
import Text from './prayerCs.md'

class Andras extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    

    render() {               
        return (
            <div className="tp-container">
                <div className="language">
                    <Link to="/prayer">en</Link>/<Link to="/cs/prayer">cs</Link>
                </div>
                <div className="tp-header">
                    < HeaderImage />
                    < HeaderImageSmall />
                    <h1>Co když je technologie modlitba?</h1>
                </div>
                <div className="tp-text">
                    <div className="tp-subtile">
                    Interview s K Allado-McDowel<br></br>
                        Danae Io
                    </div>
                    <div className="tp-main" dangerouslySetInnerHTML={{__html: Text}} >
                    </div>
                </div>
                <Link to="/cs/technicandmagic" className="tp-footer">
                    <div className="tp-footer-more">
                    další čtení:
                    </div>
                    <div className="tp-footer-title">
                    Technika <br></br>a<br></br> Magie: Úvod
                    </div>
                    <div className="tp-footer-subtitle">
                    Federico Campagna
                    </div>

                    <div className="fp-read">
                            <div className="fp-read-bck">
                            <svg height="100" width="100" viewBox="0 0 100 100" preserveAspectRatio="none">
                                <circle cx="50" cy="50" r="40" stroke="white" strokeWidth="3" fill="white" />
                            </svg>

                            </div>
                            <div className="fp-read-text">číst 3,254 slov</div>
                    </div>

                </Link>
            </div>
        )
    }
}


export default Andras