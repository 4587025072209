import React from 'react'
import Vimeo from '@u-wave/react-vimeo';

class Reis extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {               
        return (
            <div className="artwork-container">
                <div className="artwork-container-close" onClick={this.props.close}>Close</div>
                <div className="video-container">


                </div>
                <div>   This video was accessible only for a limited time period (May 7th 2020 - May 21st 2020)

</div>
<div>
    Video bylo dostupné jen po omezenou dobu (7. května 2020 - 21. května 2020)
</div>
                <div className="artwork-caption">Alice dos Reis: Under Current (2019), video, duration 15:30</div>
            </div>
        )
    }
}


export default Reis