import React from 'react'
import { Link } from 'react-router-dom'
import HeaderImage from './HeaderImage'
import HeaderImageSmall from './HeaderImageSmall'
// import Text from './TextTechnicAndMagic.jsx'
import Text from './introduction.md'

class Introduction extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    

    render() {               
        return (
            <div className="tp-container">
                <div className="language">
                    <Link to="/introduction">en</Link>/<Link to="/cs/introduction">cs</Link>
                </div>
                <div className="tp-header">
                    < HeaderImage />
                    < HeaderImageSmall />
                    <h1 className="tp-title-small">“Modernity is a continuation of sacral tendencies by othermeans”:<br/>
                    Introduction to Technologies of the Sacred
                    </h1>
                </div>
                <div className="tp-text">
                    <div className="tp-subtile">
                        Lukáš Likavčan & Hana Janečková
                    </div>
                    <div className="tp-main" dangerouslySetInnerHTML={{__html: Text}} >
                        {/* <Text /> */}
                    </div>
                </div>
                <Link to="/prayer" className="tp-footer">
                    <div className="tp-footer-more">
                    more reading:
                    </div>
                    <div className="tp-footer-title">
                        WHAT IF 
                        TECHNOLOGY 
                        WERE A PRAYER? 
                    </div>
                    <div className="tp-footer-subtitle">
                        Interview with K Allado-McDowell
                        Danae Io
                    </div>

                    <div className="fp-read">
                            <div className="fp-read-bck">
                            <svg height="100" width="100" viewBox="0 0 100 100" preserveAspectRatio="none">
                                <circle cx="50" cy="50" r="40" stroke="white" strokeWidth="3" fill="white" />
                            </svg>

                            </div>
                            <div className="fp-read-text">read 1,800 words</div>
                    </div>

                </Link>
                <Link to="/technicandmagic" className="tp-footer">
                    <div className="tp-footer-more">
                    more reading:
                    </div>
                    <div className="tp-footer-title">
                    TECHNIC AND MAGIC: INTRODUCTION
                    </div>
                    <div className="tp-footer-subtitle">
                    Federico Campagna
                    </div>

                    <div className="fp-read">
                            <div className="fp-read-bck">
                            <svg height="100" width="100" viewBox="0 0 100 100" preserveAspectRatio="none">
                                <circle cx="50" cy="50" r="40" stroke="white" strokeWidth="3" fill="white" />
                            </svg>

                            </div>
                            <div className="fp-read-text">read 1,800 words</div>
                    </div>

                </Link>
            </div>
        )
    }
}


export default Introduction