import React from 'react'

class HeaderImageSmall extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {               
        return (
        <svg version="1.0" id="Layer_1" x="0px" y="0px"
            viewBox="0 0 375 340" className="tp-header-img-small" >
        <g>
            <path className="st0" d="M286.09,107.05h70.77c9.98,0,18.13-3.44,18.13-7.64V18.14c-0.03-10-8.13-18.11-18.13-18.14l-70.77,0
                c-10,0.03-18.11,8.14-18.14,18.14v81.27C267.95,103.61,276.12,107.05,286.09,107.05z M283.35,33.54c0.03-10,8.14-18.11,18.14-18.13
                h39.97c10,0.03,18.11,8.13,18.14,18.13v39.97c-0.03,10-8.14,18.11-18.14,18.13l-39.97,0c-10-0.03-18.11-8.13-18.14-18.13V33.54z"/>
            <path className="st0" d="M33.39,76.5h40.28c1.57,0,2.83-1.27,2.83-2.84V33.39c0-1.57-1.27-2.84-2.83-2.84H33.39
                c-1.57,0-2.84,1.27-2.84,2.84v40.28C30.55,75.23,31.82,76.5,33.39,76.5z"/>
            <path className="st0" d="M301.34,76.5h40.28c1.57,0,2.83-1.27,2.83-2.84V33.39c0-1.57-1.27-2.84-2.83-2.84h-40.28
                c-1.57,0-2.83,1.27-2.83,2.84v40.28C298.5,75.23,299.77,76.5,301.34,76.5z"/>
            <path className="st0" d="M15.28,107.05h15.34h61.16c8.4,0,15.27-3.44,15.27-7.64V18.14C107.05,8.16,100.18,0,91.78,0h-76.5
                C6.88,0,0,8.17,0,18.14v81.27C0,103.61,6.88,107.05,15.28,107.05z M15.4,33.53c0-9.98,3.41-18.13,7.58-18.13h35.26l0,0.01h25.84
                c4.17,0,7.58,8.16,7.58,18.13v39.97c0,9.97-3.41,18.13-7.58,18.13h-61.1c-4.17,0-7.58-8.16-7.58-18.13V33.53z"/>
            
                <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 0.8688 179.7974)" className="st0" cx="217.47" cy="88.85" rx="7.7" ry="7.7"/>
            <path className="st0" d="M375,307.28c0-3.62-3.37-6.58-7.49-6.58c-4.12,0-7.5-6.98-7.5-15.51v-8.07c0-8.53,3.37-15.51,7.5-15.51
                c4.13,0,7.49-2.94,7.49-6.53V241.9c0-3.6-3.37-6.53-7.49-6.53c-4.12,0-7.5-2.94-7.5-6.53s-3.4-6.53-7.56-6.53s-7.56,2.96-7.56,6.58
                s3.37,6.58,7.49,6.58c4.12,0,7.5,2.92,7.5,6.48v13.07c0,3.56-3.37,6.47-7.5,6.47s-7.49,5.88-7.49,13.07s-3.38,13.06-7.5,13.06
                s-7.5,2.94-7.5,6.54s-3.34,6.53-7.44,6.53c-4.1,0-7.44-6.98-7.44-15.51v-49.76c0-7.16,3.38-13.02,7.5-13.02
                c4.12,0,7.5-2.94,7.5-6.53s3.37-6.53,7.49-6.53c4.12,0,7.5-2.94,7.5-6.53s3.35-6.53,7.44-6.53s7.44,2.94,7.44,6.53
                s3.4,6.53,7.56,6.53c4.16,0,7.56-6.98,7.56-15.51v-8.28c0-8.53-3.4-15.51-7.56-15.51c-4.16,0-7.56,2.94-7.56,6.53
                s-3.37,6.53-7.5,6.53s-7.49,2.94-7.49,6.53s-6.75,6.53-15,6.53s-14.99,2.94-14.99,6.53s-6.72,6.53-14.93,6.53
                c-8.21,0-14.94-2.92-14.94-6.48s3.37-6.48,7.5-6.48c4.13,0,7.49-2.97,7.49-6.59s-3.37-6.58-7.49-6.58c-4.12,0-7.5-2.94-7.5-6.53
                s-3.37-6.53-7.5-6.53c-4.13,0-7.49-2.92-7.49-6.48s3.37-6.48,7.49-6.48c4.12,0,7.5-2.94,7.5-6.53s3.37-6.53,7.5-6.53
                c4.13,0,7.49-2.94,7.49-6.53s3.35-6.53,7.44-6.53s7.44,6.98,7.44,15.52v8.16c0,8.53,3.4,15.51,7.56,15.51
                c4.16,0,7.56-2.94,7.56-6.53s3.37-6.53,7.49-6.53c4.12,0,7.5-2.94,7.5-6.53s3.35-6.53,7.44-6.53c4.09,0,7.44,2.94,7.44,6.53
                s3.4,6.53,7.56,6.53s7.56-5.88,7.56-13.06v-19.7c0-3.6-6.77-6.53-15.06-6.53c-8.29,0-15.05,2.94-15.05,6.53v13.12
                c0,3.57-3.35,6.48-7.44,6.48c-4.09,0-7.44-2.94-7.44-6.53s-3.37-6.53-7.5-6.53c-4.13,0-7.5-2.94-7.5-6.53s-8.01-6.53-17.8-6.53
                h-24.37c-9.79,0-17.81-5.88-17.81-13.07s-3.4-13.07-7.56-13.07c-4.16,0-7.56,5.88-7.56,13.07s-3.37,13.07-7.5,13.07
                c-4.13,0-7.49,2.94-7.49,6.53v30.31c0,8.53,3.37,15.51,7.49,15.51c4.12,0,7.5,2.92,7.5,6.48s-3.37,6.48-7.5,6.48
                c-4.13,0-7.49,2.94-7.49,6.53s-6.72,6.53-14.94,6.53s-14.93-5.88-14.93-13.07s-6.78-13.06-15.06-13.06s-15.06,2.96-15.06,6.58
                s3.37,6.58,7.49,6.58c4.12,0,7.5,5.86,7.5,13.02s-3.37,13.02-7.5,13.02s-7.49,2.96-7.49,6.58s3.37,6.58,7.49,6.58
                c4.12,0,7.5,2.94,7.5,6.53s3.37,6.53,7.5,6.53s7.49,2.92,7.49,6.48s-6.75,6.48-14.99,6.48c-8.25,0-14.99,2.94-14.99,6.53
                s-3.35,6.53-7.44,6.53c-4.09,0-7.44-2.94-7.44-6.53s-3.37-6.53-7.5-6.53s-7.49-6.98-7.49-15.51v-8.07c0-8.53,3.37-15.51,7.49-15.51
                c4.12,0,7.5-2.97,7.5-6.58s-3.37-6.59-7.5-6.59s-7.49-2.91-7.49-6.48s3.37-6.48,7.49-6.48c4.12,0,7.5-2.94,7.5-6.53V163.5
                c0-3.59-8.02-6.53-17.81-6.53h-9.37c-9.79,0-17.81-2.94-17.81-6.53s-3.37-6.53-7.49-6.53c-4.12,0-7.5-2.91-7.5-6.48
                s3.37-6.48,7.5-6.48s7.49-2.96,7.49-6.58s-6.77-6.58-15.06-6.58c-8.29,0-15.06,2.94-15.06,6.53v13.12c0,3.57-3.34,6.48-7.44,6.48
                c-4.09,0-7.44-2.94-7.44-6.53s-3.37-6.53-7.49-6.53c-4.12,0-7.5-2.94-7.5-6.53s-3.37-6.53-7.49-6.53H15
                c-8.25,0-14.99,2.96-14.99,6.58s3.37,6.58,7.5,6.58s7.49,2.94,7.49,6.53s3.38,6.53,7.5,6.53h22.56c8.21,0,14.93,2.91,14.93,6.48
                s-6.75,6.48-14.99,6.48S30,159.92,30,163.51s-6.75,6.53-15,6.53S0,173,0,176.63s3.37,6.58,7.5,6.58s7.5,2.92,7.5,6.48
                s-3.37,6.48-7.49,6.48c-4.12,0-7.5,2.97-7.5,6.59s6.75,6.58,14.99,6.58h22.56c4.09,0,7.44,5.88,7.44,13.07
                c0,4.32,1.23,8.16,3.12,10.54H15.28c-8.4,0-15.27,8.16-15.27,18.14v70.78C0,331.84,6.88,340,15.28,340h76.5
                c8.4,0,15.27-8.16,15.27-18.13v-70.78c0-7.39-3.77-13.77-9.14-16.59c4.3-1.15,7.19-3.21,7.19-5.55c0-3.59,3.35-6.53,7.44-6.53
                c4.09,0,7.44,5.88,7.44,13.06s3.37,13.07,7.5,13.07s7.49,2.91,7.49,6.48s-3.37,6.47-7.49,6.47c-4.12,0-7.5,2.97-7.5,6.59
                s3.37,6.58,7.5,6.58s7.49,2.94,7.49,6.53s3.37,6.53,7.5,6.53s7.5,2.92,7.5,6.48c0,3.56-6.75,6.48-15,6.48s-14.99,6.98-14.99,15.52
                v8.28c0,8.53,8.02,15.51,17.81,15.51h39.48c9.79,0,17.81-2.96,17.81-6.58c0-3.62-3.38-6.58-7.5-6.58c-4.12,0-7.5-5.86-7.5-13.02
                c0-7.16,6.75-13.02,15-13.02c8.25,0,14.99-2.94,14.99-6.53c0-3.59,3.35-6.54,7.44-6.54s7.44,2.94,7.44,6.54s3.37,6.53,7.49,6.53
                c4.12,0,7.5,6.98,7.5,15.52v8.17c-0.1,8.43,6.63,15.36,15.06,15.51c8.28,0,15.06-2.96,15.06-6.58c0-3.62-3.37-6.58-7.5-6.58
                c-4.13,0-7.5-5.86-7.5-13.02c0-7.16,3.38-13.02,7.5-13.02c4.12,0,7.5-2.94,7.5-6.53c0-3.59,6.72-6.53,14.93-6.53
                s14.94,2.92,14.94,6.48c0,3.56-3.37,6.48-7.5,6.48c-4.13,0-7.49,5.91-7.49,13.12s6.75,13.12,14.99,13.12
                c8.25,0,14.99,2.94,14.99,6.54s3.4,6.53,7.56,6.53s7.56-2.96,7.56-6.58c0-3.62-3.37-6.58-7.5-6.58c-4.13,0-7.5-2.92-7.5-6.48
                c0-3.56,3.38-6.48,7.5-6.48c4.12,0,7.5-2.94,7.5-6.54s3.34-6.53,7.44-6.53c4.1,0,7.44,2.95,7.44,6.53c0,3.59,6.77,6.54,15.05,6.54
                S375,310.9,375,307.28z M37.55,183.11c-4.09,0-7.44-2.92-7.44-6.48h0c0-3.56,3.35-6.48,7.44-6.48c4.09,0,7.44,2.92,7.44,6.48
                S41.64,183.11,37.55,183.11z M97.53,157.08c4.09,0,7.44,2.92,7.44,6.48s-3.35,6.48-7.44,6.48c-4.09,0-7.44-2.92-7.44-6.48
                S93.44,157.08,97.53,157.08z M52.55,183.21c4.09,0,7.44,5.86,7.44,13.02c0,7.16-3.35,13.01-7.44,13.01
                c-4.09,0-7.44-5.86-7.44-13.01C45.11,189.07,48.46,183.21,52.55,183.21z M60.11,222.41c0-7.19,3.35-13.07,7.44-13.07
                c4.09,0,7.44,5.88,7.44,13.07c0,4.32,2.46,8.16,6.22,10.54l-4.76,0H56.99C58.87,230.57,60.11,226.73,60.11,222.41z M91.66,309.39
                c0,8.37-3.41,15.22-7.58,15.22h-61.1c-4.16,0-7.58-6.85-7.58-15.22v-42.9c0-9.97,3.41-18.13,7.58-18.13h61.1
                c4.16,0,7.58,8.16,7.58,18.13V309.39z M97.53,222.3L97.53,222.3c-4.09,0-7.44-2.92-7.44-6.48s3.35-6.48,7.44-6.48
                c4.09,0,7.44,2.92,7.44,6.48S101.62,222.3,97.53,222.3z M127.46,196.28c4.13,0,7.49,2.91,7.49,6.48s-6.72,6.48-14.93,6.48v0
                c-8.22,0-14.94-2.95-14.94-6.53s-3.4-6.53-7.56-6.53c-4.16,0-7.56,2.94-7.56,6.53s-3.35,6.53-7.44,6.53
                c-4.09,0-7.44-5.88-7.44-13.07s-3.37-13.07-7.5-13.07s-7.5-2.92-7.5-6.48s3.35-6.48,7.44-6.48h15c4.09,0,7.44,2.94,7.44,6.53
                s3.4,6.53,7.56,6.53c4.16,0,7.56-2.94,7.56-6.53s6.72-6.53,14.94-6.53s14.93,2.92,14.93,6.48s-3.37,6.48-7.49,6.48
                c-4.12,0-7.5,2.97-7.5,6.58S123.34,196.28,127.46,196.28z M142.52,274.57L142.52,274.57c-4.1,0-7.44-2.92-7.44-6.48
                s3.34-6.48,7.44-6.48c4.1,0,7.44,2.92,7.44,6.48S146.61,274.57,142.52,274.57z M142.52,326.83c-4.1,0-7.44-2.92-7.44-6.48
                c0-3.56,3.34-6.48,7.44-6.48c4.09,0,7.44,2.91,7.44,6.48C149.96,323.92,146.61,326.83,142.52,326.83z M157.51,313.76
                c-4.09,0-7.44-2.91-7.44-6.48c0-3.57,3.35-6.48,7.44-6.48c4.09,0,7.44,2.91,7.44,6.48l0,0
                C164.95,310.85,161.6,313.76,157.51,313.76z M192.13,287.64h-24.25c-9.8,0-17.81-2.92-17.81-6.48s6.75-6.48,14.99-6.48
                s14.99-2.94,14.99-6.53s6.72-6.53,14.94-6.53s14.93,5.85,14.93,13l0,0C209.93,281.78,201.92,287.64,192.13,287.64z M209.99,235.37
                c-8.22,0-14.93-5.86-14.93-13.02s3.34-13.01,7.44-13.01l0,0c4.09,0,7.43,2.94,7.43,6.53s3.38,6.53,7.5,6.53
                c4.12,0,7.5,2.92,7.5,6.48S218.21,235.37,209.99,235.37z M239.92,255.02c0,3.56-3.35,6.47-7.44,6.47s-7.44-2.91-7.44-6.48l0,0
                v-13.07c0-3.56,3.35-6.48,7.44-6.48s7.44,2.92,7.44,6.48V255.02z M239.92,215.82c0,3.56-3.35,6.48-7.44,6.48s-7.44-2.92-7.44-6.48
                s3.35-6.48,7.44-6.48S239.93,212.26,239.92,215.82L239.92,215.82z M262.47,130.95c4.09,0,7.44,2.92,7.44,6.49l0-0.01
                c0,3.57-3.34,6.48-7.44,6.48c-4.1,0-7.44-2.91-7.44-6.48S258.38,130.95,262.47,130.95z M247.48,300.7c-4.09,0-7.44-2.92-7.44-6.48
                c0-3.56,3.35-6.48,7.44-6.48s7.44,2.92,7.44,6.48l0,0C254.92,297.78,251.57,300.7,247.48,300.7z M254.98,196.17
                c-8.21,0-14.94-2.91-14.94-6.48s3.37-6.48,7.5-6.48s7.49-2.94,7.49-6.53s3.35-6.53,7.44-6.53s7.44,5.86,7.44,13.02l0,0
                C269.91,190.31,263.19,196.17,254.98,196.17z M299.9,248.49v13.06c0,7.16-8.02,13.02-17.81,13.02h-9.25
                c-9.79,0-17.81-5.86-17.81-13.02v-19.6c0-3.56,8.02-6.48,17.81-6.48h9.25c9.79,0,17.81,2.92,17.81,6.48V248.49z"/>
            <path className="st0" d="M133.98,30.68c8.41,0.01,15.23,6.8,15.28,15.21c-0.04,8.41-6.87,15.21-15.28,15.22
                c-8.42,0.02-15.25,6.85-15.27,15.27v68.86c0,4.2,8.17,7.64,18.14,7.64h9.55c9.97,0,18.14,3.43,18.14,7.63
                c0.03,4.23,3.47,7.65,7.7,7.64c4.25-0.01,7.69-3.45,7.7-7.7c0-4.23-3.41-7.67-7.63-7.7c-4.2,0.01-7.61-3.38-7.64-7.58V129.9
                c0-4.17,6.87-7.58,15.27-7.58c8.44-0.04,15.27-6.89,15.28-15.34c0-8.43-3.44-15.34-7.64-15.34c-4.2,0-7.64-6.87-7.64-15.27
                s-3.44-15.27-7.63-15.27c-4.2,0.01-7.61-3.38-7.64-7.58c0-4.16,6.85-7.58,15.22-7.58c8.41,0.04,15.2,6.87,15.21,15.27
                c0,8.4,3.47,15.28,7.7,15.28c4.23,0,7.67-3.41,7.7-7.64c0-4.2,6.87-7.64,15.27-7.64s15.27-3.44,15.27-7.63
                c0.01-4.21,3.43-7.63,7.64-7.64c4.2,0,7.63-6.9,7.63-15.34s-3.46-15.34-7.7-15.34c-4.23-0.01-7.67,3.41-7.7,7.64
                c-0.01,4.21-3.43,7.63-7.64,7.64c-4.21,0.01-7.62,3.42-7.63,7.63c0.01,4.2-3.38,7.61-7.58,7.64c-4.17,0-7.58-8.16-7.58-18.14v-9.55
                c-0.03-10-8.14-18.11-18.14-18.14h-9.67c-9.98,0-18.13,3.44-18.13,7.64s-6.85,7.63-15.22,7.63c-8.37,0-15.21-3.44-15.21-7.63
                C134.07,3.41,130.63,0,126.4,0c-4.23,0-7.7,6.9-7.7,15.34C118.71,23.78,125.53,30.63,133.98,30.68z M172.23,91.78
                c4.18,0.01,7.56,3.4,7.58,7.58c-0.01,4.18-3.4,7.56-7.58,7.58c-4.18-0.01-7.56-3.4-7.58-7.58
                C164.66,95.17,168.05,91.79,172.23,91.78z M172.23,15.4c4.18,0.01,7.56,3.4,7.58,7.58c-0.01,4.18-3.4,7.56-7.58,7.58
                c-4.18-0.01-7.56-3.4-7.58-7.58l0,0C164.66,18.8,168.05,15.42,172.23,15.4z M149.31,76.5c8.36,0,15.22,3.4,15.22,7.57
                c-0.03,4.2-3.44,7.58-7.64,7.58c-4.2,0-7.63,6.88-7.63,15.28s-3.41,15.27-7.58,15.27s-7.58-6.85-7.58-15.22l0-15.27v-7.64
                C134.1,79.91,140.95,76.5,149.31,76.5z"/>
            <ellipse className="st0" cx="202.49" cy="136.38" rx="7.7" ry="15.34"/>
            <ellipse className="st0" cx="15.34" cy="224.1" rx="15.34" ry="7.7"/>
            
                <ellipse transform="matrix(0.9732 -0.2298 0.2298 0.9732 -50.2815 70.4522)" className="st0" cx="277.4" cy="251.15" rx="7.7" ry="7.7"/>
            <path className="st0" d="M58.36,263.5h-9.67c-9.97,0-18.13,6.87-18.13,15.27v23.03c0,4.2,8.16,7.64,18.13,7.64h9.67
                c9.98,0,18.14-3.44,18.14-7.64v-23.03C76.5,270.38,68.34,263.5,58.36,263.5z"/>
            
                <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -127.3913 357.0502)" className="st0" cx="367.3" cy="332.3" rx="7.7" ry="7.7"/>
        </g>
        </svg>

        )
    }
}


export default HeaderImageSmall