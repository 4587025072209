import React from 'react'
import { Link } from "react-router-dom"

class Andras extends React.Component {

    constructor(props) {
        super(props)
        var userLang = navigator.language || navigator.userLanguage; 
        this.state = {
            locale: userLang
        }
    }

    componentDidMount() {
        // if (this.state.userLang === 'cs-CZ') {
        //     this.history.push('/cs')
        // }
    }

    

    render() {               
        return (
            <div className="fp-container">
                <div className="language">
                    <Link to='/en'>en</Link>/<Link to='/'>cs</Link>
                </div>
                {/* <div className="fp-introduction">
                Display is presenting to you two translation of essays related to our online exhibition Technology of The Sacred. 
                </div> */}
                <div className="fp-col-container">
                    <Link to="/cs/introduction" className="fp-col-width">
                        <h2 className="fp-title">Modernita je pokračování sakrálních tendencí jiným způsobem</h2>
                        <div className="fp-subtitle">Úvod do Technologií posvátna<br></br>Lukáš Likavčan &amp; Hana Janečková</div>
                        <div className="fp-main">
                        Náboženské praktiky se vždy úzce prolínali s nástroji technologického zprostředkování. Jen si vzpomeňte na tabernákl ze Starého zákona, na komplexní matematiku věštění v kultuře afrických Jorubů, na buddhistickou spirituální automatizaci pomocí modlitebních mlýnků, na dějiny japonské robotiky, na astrologické aplikace v smartphonech používající pokročilé neuronové sítě, nebo na příběhy...

                        </div>
                        <div to="/introduction" className="fp-read">
                            <div className="fp-read-bck">
                            <svg height="100" width="100" viewBox="0 0 100 100" preserveAspectRatio="none">
                                <circle cx="50" cy="50" r="40" stroke="white" strokeWidth="3" fill="white" />
                            </svg>

                            </div>
                            <div className="fp-read-text">číst  1,036 slov</div>
                        </div>
                    </Link>
                    <Link to="/space" className="fp-col-width">
                        <h2 className="fp-title">Technologie posvátna</h2>
                        <div className="fp-subtitle">Online výstava<br /><br />András Cséfalvay, Alice dos Reis, Halima Haruna, Holly Childs<br/> Danae Io, Adéla Součková</div>
                        <div className="fp-main">
                        Úkolem výzkumné výstavy ‚Technologie posvátna‘ je přehodnotit hranice mezi technologií a náboženstvím či mysticismem. Odkouzlený svět moderní doby je v skutečnosti jenom pokračováním sakrálních tendencí jiným způsobem – magické myšlení se kamufluje do podoby technické racionality, která potřebuje sféru posvátna jako skrytý zdroj své sekulární vznešenosti. Tento přístup nám umožňuje nově...
                        </div>
                        <div className="fp-read">
                            <div className="fp-read-bck">
                            <svg height="100" width="100" viewBox="0 0 100 100" preserveAspectRatio="none">
                                <circle cx="50" cy="50" r="40" stroke="white" strokeWidth="3" fill="white" />
                            </svg>

                            </div>
                            <div className="fp-read-text">navštívit výstavu</div>
                        </div>
                    </Link>
                    <Link to="/cs/prayer" className="fp-col-width">
                        <h2 className="fp-title">Co když je technologie modlitba?</h2>
                        <div className="fp-subtitle">Interview s K Allado-McDowell<br />Danae Io</div>
                        <div className="fp-main">
                        K Allado-McDowell je jednou z vůdčích osob programu Google Research Artist + Machine Intelligence, který usnadňuje spolupráci mezi výzkumníky*cemi z Google AI, umělci*kyněmi a kulturními institucemi. Vedle dvaceti let zkušeností v oblasti softwarového designu a inženýrství má K též magisterský titul v oboru fotografie a pravidelně vystupuje se svojí akustickou a elektronickou hudbou. V tomto rozhovoru se zabýváme vztahem mezi věštebnými praktikami a strojovým učením, multidimenzionalitou umělých neuronových sítí coby alternativním schématem...

                        </div>
                        <div to="/technicandmagic" className="fp-read">
                            <div className="fp-read-bck">
                            <svg height="100" width="100" viewBox="0 0 100 100" preserveAspectRatio="none">
                                <circle cx="50" cy="50" r="40" stroke="white" strokeWidth="3" fill="white" />
                            </svg>

                            </div>
                            <div className="fp-read-text">číst 2,490 slov</div>
                        </div>
                    </Link>
                    <Link to="/cs/technicandmagic" className="fp-col-width">
                        <h2 className="fp-title">Technika <br></br>a<br></br> Magie: Úvod</h2>
                        <div className="fp-subtitle">Federico Campagna</div>
                        <div className="fp-main">
                        Tato kniha je určena těm, kteří byli poraženi dějinami i přítomností. Nemá být manuálem, jak přetavit současnou porážku v budoucí výhru, ale spíše zkazkou o jisté skryté stezce, která vede z bojiště dál do lesa na druhé straně. Tuto knihu jsem začal psát na sklonku podzimu 2016, kdy narůstající fašismus spojil síly s pokračující environmentální devastací a kapitalistickou biopolitikou, v trudné Valhale sil válčících o podobu světa. Bývaly doby, kde jsem si myslel, že ten výčet hrůz, které naše doba páchá, si žádá v jádru politické řešení. Měl jsem za to, že jelikož se problémy dotýkají...
                        </div>
                        <div to="/technicandmagic" className="fp-read">
                            <div className="fp-read-bck">
                            <svg height="100" width="100" viewBox="0 0 100 100" preserveAspectRatio="none">
                                <circle cx="50" cy="50" r="40" stroke="white" strokeWidth="3" fill="white" />
                            </svg>

                            </div>
                            <div className="fp-read-text">číst 3,254 slov</div>
                        </div>
                    </Link>
                </div>

            </div>
        )
    }
}


export default Andras