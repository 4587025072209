import './style/style.scss'

import * as React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App'


ReactDOM.render(
    <App />,
    document.getElementById('root')
  );