import React , { Suspense }from 'react'
const Status = React.lazy(() => import('./Status'));


class Header extends React.Component {
  
    componentDidMount () {
    }
  
    componentWillUnmount () {

    }
  
 
    render () {
      console.log(this.props.location.pathname.includes('space'))
      return (
        <div>
          <div className="header">
            <a href="/" className="header-title">
                <span className="header-logo">display </span> 
                <span className="header-logo-subtitle">association for research and collective practice</span>
            </a>              
          </div>
          <div className="menu-open" onClick={this.props.handleOnClick}>
                Info
          </div>
          { this.props.location.pathname.includes('space') && 
            <div className="footer-right" onClick={this.props.handleMute}>
              { this.props.volume ? 'Mute': 'Unmute'}
            </div> 

          }
          {/* <div className="footer-left">
            <Suspense fallback={<div>Loading...</div>}> 
              <Status updateStatus={this.props.updateStatus}/>
            </Suspense>
          </div>  */}
        </div>

      )
    }
  }
  
  
  export default Header